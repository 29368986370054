import { type EaseToOptions } from 'mapbox-gl'
import { useCallback } from 'react'
import { type MapRef } from 'react-map-gl'
import { aIsMapEasing, useSetAtom } from '~/atoms'

export default function useMapEaseTo(mapRef: React.RefObject<MapRef>) {
  const setIsMapEasing = useSetAtom(aIsMapEasing)
  const mapEaseTo = useCallback(
    (easeToOptions: EaseToOptions) => {
      if (!mapRef) {
        return
      }
      const options = {
        duration: 500,
        ...easeToOptions,
      }
      setIsMapEasing(true)
      mapRef.current!.easeTo(options)
      setTimeout(() => {
        setIsMapEasing(false)
      }, options.duration + 100)
    },
    [mapRef, setIsMapEasing]
  )
  return mapEaseTo
}
