import { useLocation, useNavigate } from '@remix-run/react'
import { useMemo } from 'react'
import { useMap } from 'react-map-gl'
import { useSearchesQuery } from '~/api'
import {
  aMapFilters,
  aUser,
  getParamsFromMapFilters,
  useAtomValue,
  useSetAtom,
} from '~/atoms'
import { Command } from '~/components/ui'

export default function GlobalSavedSearches({
  search,
  condition,
  isCurrentPage,
  setIsOpen,
}: {
  search: string
  condition: boolean
  isCurrentPage: boolean
  setIsOpen: (isOpen: boolean) => void
}) {
  const user = useAtomValue(aUser)
  const location = useLocation()
  const navigate = useNavigate()
  const mapRef = useMap()
  const setMapFilters = useSetAtom(aMapFilters)

  const { searches, isLoading } = useSearchesQuery({
    key: ['saved-searches', user?._id || ''],
    find: { 'user._id': user?._id },
    sort: { created: -1 },
    enabled: !!user,
  })

  const filteredSearches = useMemo(() => {
    const lower = search.toLowerCase()
    return searches.filter((s) => {
      return (
        s.name.toLowerCase().includes(lower) ||
        (s.description && s.description.toLowerCase().includes(lower))
      )
    })
  }, [searches, search])

  if (!user || !condition || filteredSearches.length === 0) {
    return null
  }

  return (
    <Command.Group heading="Saved Searches">
      {filteredSearches.map((search) => {
        if (location.pathname.includes('/admin')) {
          const params = getParamsFromMapFilters(search.filters)
          const searchParams = new URLSearchParams(params)
          searchParams.set('lat', search.lat.toString())
          searchParams.set('lng', search.lng.toString())
          searchParams.set('z', search.z.toString())

          return (
            <Command.Item
              key={search._id}
              onSelect={() => {
                navigate(`/?${searchParams}`)
                setIsOpen(false)
              }}>
              {search.name}
            </Command.Item>
          )
        }
        return (
          <Command.Item
            key={search._id}
            onSelect={() => {
              if (!mapRef.current) {
                console.error('Map ref not found')
                return
              }
              mapRef.current!.flyTo({
                center: [search.lng, search.lat],
                zoom: search.z,
                duration: 500,
              })
              setMapFilters(search.filters)
              setIsOpen(false)
            }}>
            {search.name}
          </Command.Item>
        )
      })}
    </Command.Group>
  )
}
